import React from 'react';
import styled from "styled-components";
import { BsFacebook, BsTwitter, BsYoutube, BsBehance, BsInstagram } from 'react-icons/bs';
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { footerTextAnimation } from "animation";

function Footer() {
  const [element, controls] = useScroll();
  return (
    <Foot ref={element}>
      <motion.span
      variants={footerTextAnimation}
      animate={controls}
      transition={{
        delay: 0.02,
        type: "tween",
        duration: 0.8,
      }}
      >
        &copy; 2022 Caddyshack City. Made by <a href='https://www.miraelite.com/' className='link' target="_blank">Mira Ellite</a> with love
      </motion.span>
      <motion.div className="footer__social__icons"
      variants={footerTextAnimation}
      animate={controls}
      transition={{
        delay: 0.02,
        type: "tween",
        duration: 0.8,
      }}  
      >
        <a href='https://www.facebook.com/caddyshackcity?mibextid=LQQJ4d' target="_blank" ><BsFacebook /></a>
        {/* <BsTwitter /> */}
        {/* <BsYoutube /> */}
        <a href='https://www.instagram.com/caddyshackcity/?igshid=YmMyMTA2M2Y%3D&fbclid=IwAR3yXkTlvpLoMzDsvJx94pI5pi_Aansx8Fdk3aDEVDG6BjP_vTvS5HmKlWo' target="_blank" ><BsInstagram /></a>
      </motion.div>
    </Foot>
  )
}

const Foot = styled(motion.footer)`
background-color: var(--primary-color);
color: #fff;
display: flex;
justify-content: space-between;
align-items: center;
padding: 5rem 10rem;
.link{
  text-decoration: none;
  transition: 0.5s ease-in-out;
  // border-radius: 10px;
  background: #fff;
  color: var(--third-color);
  font-weight: 500;
  padding: 0 0.5rem;
  &:hover {
    background-position: -100% 100%;
    color: var(--secondary-color);
    font-size: 1.2rem;
  }
}

.footer__social__icons {
  display: flex;
  gap: 2rem;

  a{
    font-size: 1.4rem;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    text-decoration: none;
    color: #fff;
    &:hover {
      color: var(--secondary-color);
      font-size: 2rem;
    }
  }

  // svg {
  //   font-size: 1.4rem;
  //   cursor: pointer;
  //   transition: 0.5s ease-in-out;
  //   text-decoration: none;
  //   color: #fff;
  //   &:hover {
  //     color: var(--secondary-color);
  //     transform: scale(1.05);
  //   }
  // }
}
@media screen and (min-width: 280px) and (max-width: 1080px) { 
  padding: 2rem;
  flex-direction: column;
  text-align: center;
  gap: 2rem;
}
`;

export default Footer