import React, { Suspense } from 'react'
import styled from "styled-components";
import { Canvas } from "@react-three/fiber";
import {OrbitControls, Environment} from "@react-three/drei"
import { AdaptiveDpr, AdaptiveEvents } from "@react-three/drei";
// import { Model } from '../assets/Miracoin';
// import { Model } from '../assets/Untitled';
// import { Model } from '../assets/Untitled';

// import { Model } from '../assets/Elite3d';
// import { Suspense } from "react";

import { Model } from '../assets/Scene';


// import { Plane1 } from '../assets/Plane1';




const Container = styled.div`
  width: 100vw;
  height: 80vh;
  // position: fixed;
  // top: 10rem;
  right: 0;
  z-index: 5;
  background-color: transparent;
  transition: all 0.3s ease;
  cursor: grab;
  @media screen and (min-width: 280px) and (max-width: 1080px) { 
    width: 100%;
    background: transparents;
    height: 30vh;
`;

const Golfballmodel = () => {
  return (
    <Container>
        <Canvas camera={{ fov:30 }} style={{background:"#015f2d"}} >
            <ambientLight intensity={0.2}/>
            <directionalLight position={[1,0,0]} intensity={0.2}/>
            <Suspense fallback={null}>
                <Model />
            </Suspense>
            <AdaptiveDpr pixelated />
          <AdaptiveEvents /> 
            <Environment preset='sunset' />
            <OrbitControls enableZoom={false}  target={[1,1,0]}/>
        </Canvas>
    </Container>
  )
}

export default Golfballmodel