/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Scott VanArsdale (https://sketchfab.com/vanart)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/golf-ball-b0b50a16aa5d431e8ed799c1a413025c
title: Golf Ball
*/

import React, { useRef, useLayoutEffect  } from 'react'
import { useGLTF } from '@react-three/drei'
import { useThree } from "@react-three/fiber";

export default function Model3(props) {
  const group = useRef();
  const { nodes, materials } = useGLTF('/scene.gltf')
  let camera = useThree((state) => state.camera);

  useLayoutEffect(() => {
    camera.position.set(-0.1,0.4,10);
    // materials.Body.color.set("#9BB5CE");

    if(window.matchMedia("(max-width: 24em)").matches){
      camera.fov = 60;
      camera.position.set(-0,0,10);
      camera.updateProjectionMatrix();
    }


        
  }, []);
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.defaultMaterial.geometry} material={materials.initialShadingGroup} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/scene.gltf')
