import React, { useRef } from 'react';
import styled from "styled-components";
import Title from './Title';
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { contactAnimation } from "animation";
import emailjs from '@emailjs/browser';

function Contact() {
  const [element, controls] = useScroll();


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // emailjs.sendForm('service_aqjn99j', 'template_06vh9nq', form.current, '1gSIhT1pTPmg47M30')
    emailjs.sendForm('service_13lw872', 'template_o35hc4c', form.current, 'iwHgQaWRLHn8mKYxc')
      .then((result) => {
          console.log(result.text);
          alert("Thank you for getting in touch! We appreciate you contacting Caddyshack city. One of our colleagues will get back in touch with you soon! Have a great day!");
          document.getElementById('myInput').value =''
          document.getElementById('myInput1').value =''
          document.getElementById('myInput2').value =''
        }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <Section id="contact" ref={element}>
      <Title value="contact" />
      <motion.div className="contact"
      variants={contactAnimation}
      animate={controls}
      transition={{
        delay: 0.03,
        type: "tween",
        duration: 0.8,
      }}
      >
        <div className="contact__title">
          <p>Stay in touch with us </p>
          <h2>Quick Contact</h2>
        </div>
        <div className="contact__data">
          <div className="contact__data__description">
            {/* <h4>Just to say hi !!</h4> */}
            <p>
              If you have any questions simply use the following contact details.
            </p>
            {/* <p>
              Business Owners to take their Online Presence 
              to the next level. We are in the business of Bulk SMS, Digital Marketing.
            </p> */}
            <div>
              <p>
                <strong>Address:</strong> 21 King Edward Tce, Woolston, Christchurch, New Zealand
              </p>
              <p>
                <strong>Email:</strong> info@ferrymeadgolf.co.nz
              </p>
              <p>
                <strong>Website:</strong> www.caddyshack.co.nz
              </p>
            </div>
          </div>
          <div className="contact__data__form">
            {/* <input type="text" placeholder='name' />
            <input type="email" placeholder='email' />
            <textarea placeholder='message'></textarea>
            <button

            
            >Send Message</button> */}
                <form ref={form} onSubmit={sendEmail}>
                <label>Name</label>
                <input id="myInput" type="text" name="user_name" placeholder ="please write your name"/>
                <label>Email</label>
                <input id="myInput1" type="email" name="user_email" placeholder ="please write your e-mail"/>
                <label>Message</label>
                <textarea id="myInput2" type="text" name="message" placeholder ="please write your messages"/>
                <button
                   >Send Message</button>
              </form>
          </div>
        </div>
      </motion.div>
    </Section>
  )
}

const Section = styled.section`
overflow: hidden;

min-height: 100vh;
// background: var(--third-color);

.contact {
  color: var(--primary-color);
  margin: 0 18rem;
  &__title {
    margin: 6rem 0rem 2rem 0;
    padding-top: 2rem;
    p {
      text-transform: uppercase;
      color: var(--primary-color);
    }
    h2 {
      font-size: 2rem;
      color: var(--secondary-color);
    }
  }
  &__data {
    display: grid;
    grid-template-columns: 1fr 1.2fr;
    gap: 4rem;
    &__description {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      h4 {
        font-size: 1.5rem;
      }
      p {
        text-align: justify;
        margin-top: 20px;
      }
      div {
        p {
          strong {
            text-transform: uppercase;
          }
        }
      }
    }
    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      // border: 1px solid green;
      height: 50vh;
      input,
      textarea {
        // text-transform: uppercase;
        border: none;
        border-bottom: 0.1rem solid var(--secondary-color);
        width: 100%;
        color: var(--secondary-color);
        padding: 0.5rem;
        font-family: var(--font-family);
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: gray;
          font-style: italic;

        }
      }
      textarea {
        width: 100%;
        height: 50%;
        resize: none;
      }
      button {
        width: 100%;
        background-color: transparent;
        border: 0.1rem solid var(--secondary-color);
        height: 3rem;
        color: var(--secondary-color);
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        &:hover {
          background-color: var(--secondary-color);
          color: #fff;
        }
      }
    }
  }
}
@media screen and (min-width: 280px) and (max-width: 1080px) { 
  .contact {
    margin: 1rem;
    padding: 0 1rem;
    &__title {
      margin: 0;
      text-align: center;
      p {
        font-size: 0.8rem;
      }
      h2 {
        font-size: 1.3rem;
      }
    }
    &__data {
      grid-template-columns: 1fr;
      margin: 0;
      p {
        text-align: left;
      }
      &__form {
        button {
          height: 3rem;
        }
      }
    }
  }
}
`;

export default Contact