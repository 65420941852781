import React from 'react';
import styled from "styled-components";
// import home from "assets/home.png";

import home from "assets/hero3.jpg";
import play from "assets/play.png";
import Navbar from './Navbar';
import { motion } from "framer-motion";
import { homeAnimation, homeInfoAnimation } from "animation";


// aminated text
import Typewriter from "typewriter-effect";
import GolfBallModel from './Golfballmodel'

function Home() {
  return (
    <Section id="home">
      <Navbar />
      {/* <GolfBallModel /> */}
      <motion.div className="home"
      variants={homeAnimation}
      transition={{ delay: 0.3, duration: 0.6, type: "tween" }}
      >
        <div className="content">
          <div className="title">
            <h1>Welcome to </h1>
          </div>
          <Title>
          <Typewriter
        options={{
          autoStart: true,
          loop: true,
        }}
        onInit={(typewriter) => {
          typewriter
            .typeString(`<span class="text-1">CaddyShack city</span>`)
            .pauseFor(2000)
            .deleteAll()
            .typeString(`<span class="text-2"> the most exciting indoor mini golf</span>`)
            .pauseFor(2000)
            .deleteAll()
            .typeString(`<span class="text-3">the ultimate experience</span>`)
            .pauseFor(2000)
            .deleteAll()
            // .typeString(`<span class="text-3">Dr.Man Ha</span>`)
            // .pauseFor(2000)
            // .deleteAll()
            .start();
        }}
      />  
          </Title>
          <div className="subTitle">
            <p>
            Located in our indoor, all-weather venue just a few minutes drive from Christchurch’s centre .   Each of our 18 holes incorporates a different theme with some amazing scale models, and each offers different skill challenges and fun. 
            </p>  
          </div>
          {/* <img src={play} alt="Play Button" /> */}
        </div>  
      </motion.div>

      
 

      <motion.div className="info"
      variants={homeInfoAnimation}  
      transition={{ delay: 0.3, duration: 0.6, type: "tween" }}
      >
          <div className="grid">
            <div className="col">
                <strong>CaddyShack City</strong>
                <p>Indoor Minigolf</p>
            </div>
            <div className="col">
                <strong>Email</strong>
                <p>info@ferrymeadgolf.co.nz</p>
            </div> 
            <div className="col">
                <strong>Phone</strong>
                <p>03 384 1566</p>
            </div> 
            <div className="col">
                <strong>Address</strong>
                <p>21 King Edward Tce</p>
                <p>Woolston, Christchurch 8023 </p>
            </div> 
            <div className="col">
                <strong>Services</strong>
                <p>Indoor Minigolf</p>
                <p>Kid Parties</p>
            </div> 
            <div className="col">
                <strong>Opening Hours</strong>
                <p><a href="#working" className='link'>see more</a></p>
                
            </div>   
          </div>
      </motion.div>
    </Section>
  );
}

const Section = styled.section`
  // background: url(${home}) no-repeat center;
  background: url(${home}) no-repeat 000px -100px;
  min-height: 100vh;
  background-size: cover;
  position: relative;
  .home{
    height: 100%;
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 56%;
      color: #fff;
      gap: 1.2rem;
      margin-top: 8rem;
      padding-left: 12rem;
      .title {
        h1 {
          font-size: 3rem;
          line-height: 5.3rem;
        }
      }
      .subTitle {
        p {
          width: 90%;
          margin-bottom: 2rem;
        }
      }
    }
  }
  .info {
    position: absolute;
    bottom: -6rem;
    right: 0;
    background-color: var(--secondary-color);
    padding: 4rem;
    .grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem;
      color: #fff;
      .col{
        .link{
          pointer: cursor;
          text-decoration:none;
          color: white;
        }
        
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    background: url(${home}) no-repeat -100px -300px ;
    .home {
      .content {
        margin-top: 4rem;
        padding-left: 3rem;
        // border: 1px solid red
        .title {
          h1 {
            width: 150%;
            font-size: 1.5rem;
            line-height: 1rem;
            // border: 1px solid red
          }
        }
        .subTitle {
          p {
            width: 180%;
            margin-bottom: 2rem;
          }
        }
      }
    }
    .info {
      position: initial;
      .grid {
        grid-template-columns: 1fr;
      }
    }
  }

  @media screen and (min-width: 1081px) and (max-width: 1366px) {

    .home {
      .content {
        margin-top: 4rem;
        padding-left: 12rem;
        // border: 1px solid red
        .title {
          h1 {
            width: 100%;
            font-size: 2rem;
            line-height: 1rem;
            // border: 1px solid red
          }
        }
      }
    }
    .info {
      position: absolute;
      bottom: -16rem;

    }
  }
`;

const Title = styled.h2`
  // font-size: ${(props) => props.theme.fontxxl};
  font-size: 3rem;
  text-transform: capitalize;
  width: 80%;
  color: white;
  align-self: flex-start;

  span {
    text-transform: uppercase;
    // font-family: "Akaya Telivigala", cursive;
  }
  .text-1{
      color: white;
  }
  .text-2{
      color: white;
  }
  .text-3{
      color: white;
  }

  @media (max-width: 70em) {
    font-size: 2rem;

  }
  @media (max-width: 48em) { 
    align-self: left;
    text-align:left;
  }
  @media (max-width: 40em){
    width: 90%;
  }

`;





export default Home