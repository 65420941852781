import React from 'react';
import styled from "styled-components";
import Title from './Title';
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { contactAnimation } from "animation";
import logo from "assets/cshour.png";


function Contact() {
  const [element, controls] = useScroll();
  return (
    <Section id="working" ref={element}>
      <Title value="Hours" />
      <motion.div className="contact"
      variants={contactAnimation}
      animate={controls}
      transition={{
        delay: 0.03,
        type: "tween",
        duration: 0.8,
      }}
      >
        <div className="contact__title">
              <img className="img" src={logo} alt="logo" />

        </div>
        <div className="contact__data">
          <div className="contact__data__description">
          <div className='holiday'>
              <h4>SCHOOL / PUBLIC HOLIDAY OPENING HOURS</h4>
            <p >SUNDAY TO FRIDAY : <strong> 10:00 am - 6:00 pm</strong> (last tee off time - 4:30pm) </p>
            <p >SATURDAY :<strong> 10:00 am - 7:00 pm</strong> (last tee off time - 5:30pm)</p>
            {/* <p >SUNDAY : <strong>     10:00 am - 6:00 pm</strong> (last tee off time - 4:30pm) </p> */}
          </div>
          <br/>
          <br/>
          <br/>
          <h4>STANDARD OPENING HOURS</h4>
          <p>effective from 26th April 2023</p>
            <div className='close'>
                  {/* <h4>CLOSING TIME</h4>  */}
            {/* <p>MONDAY | TUESDAY      : <strong>CLOSED</strong></p> */}
            <p>MONDAY - FRIDAY      : <strong>CLOSED</strong></p>

            {/* <p>WEDNESDAY | THURSDAY | FRIDAY     : <strong>10:30AM - 5PM</strong> (last tee off time - 3:30pm)</p> */}


            </div>
          <div className='open'>
                      {/* <h4>OPENNING TIME</h4> */}
            <p >SATURDAY : <strong>     10:00 am - 7:00 pm</strong> (last tee off time - 5:30pm) </p>
            <p >SUNDAY : <strong>     10:00 am - 6:00 pm</strong> (last tee off time - 4:30pm) </p>
          </div>




          </div>
          <div className="contact__data__form">
            <div>
              <h2>CLOSED  </h2>
              <h4>XMAS DAY | BOXING DAY | NEW YEAR DAY</h4>
            </div>

            <div className='note'>
              <span>Booking for dates outside the Standard hours listed above may be possible for $250 minimum spend to open after standard operating hours - feel free to enquire </span>
            </div>
            <div className='note'>
            <span>If you have any questions simply use the following contact details</span>
            </div>
            <div>

              <p>
                <strong>Phone:</strong> 03 384 1566
              </p>
              <p>
                <strong>Email:</strong> info@ferrymeadgolf.co.nz
              </p>

            </div> 
          </div>
        </div>
      </motion.div>
    </Section>
  )
}

const Section = styled.section`
overflow: hidden;
background: var(--secondary-color);
min-height: 100vh;
width: 100vw;
margin-bottom: 0rem;

.contact {
  color: white;
  margin: 0 18rem;
  // border: 1px solid green;
  &__title {
    margin: 6rem 0 3rem 0;
    .img{
      height: 100px;
    }
    p {
      // text-transform: uppercase;
      color: white;
    }
    h2 {
      margin-top: 2rem;
      font-size: 2rem;
      color: var(--third-color);
    }
  }
  &__data {
    // border: 1px solid green;

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    margin-bottom: 4rem;
    &__description {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;


      .close{
        padding-bottom: 0rem;
      }

      .open{
        padding-bottom: 1rem;
      }
      .holiday{
        padding-top: 2rem;
      }
      h4 {
        font-size: 1.5rem;
        color: var(--third-color);
        // background: transparent;
        text-shadow: 0 0 5px #fff, 
        0 0 15px #fff,
        0 0 25px #fff,
        0 0 40px #fff;
        
      }

      p {
        text-align: justify;
        margin-top: 20px;
      }
      div {
        p {
          strong {
            text-transform: uppercase;
          }
        }
      }
    }
    &__form {
      display: flex;
      border: 1px dotted white;
      flex-direction: column;
      align-items: left;
      padding: 2rem;
      padding-top: 2rem;
      gap: 2rem;
      .note{
             
        font-size: 1rem;
        color: #fff;
        text-align: justify;
        // border: 1px dotted white;
     
      }

      h2{
        font-size: 1.5rem;
        color: #fff;
        margin-bottom: 0px;
        text-align: center;

      }
      h4 {
        font-size: 1.5rem;
        color: var(--third-color);
        text-shadow: 0 0 5px #fff, 
        0 0 15px #fff,
        0 0 25px #fff,
        0 0 40px #fff;
      }
      
    }
  }
}
@media screen and (min-width: 280px) and (max-width: 1080px) { 
  .contact {
    margin: 1rem;
    padding: 0 1rem;
    &__title {
      .img{
        margin-top: 1rem;
        height: 50px;
      }
      margin: 0;
      text-align: center;
      p {
        font-size: 0.8rem;
      }
      h2 {
        font-size: 1.5rem;
      }
    }
    &__data {
      grid-template-columns: 1fr;
      margin-top: 2rem;
      h4{
        text-align: center;
        font-size: 1.3rem;

      }
      p {
        text-align: center;
        font-size: 0.8rem;
      }
      &__form {
        gap: 1rem;
        text-align: center;
        span{
          font-size: 0.8rem;
        }
        
      }
    }
  }
}

@media screen and (min-width:1081px) and (max-width: 1366px) { 
  .contact {
    color: white;
    margin: 0 10rem;
    &__title {
      margin: 6rem 0 3rem 0;
      .img{
        height: 100px;
      }
      p {
        // text-transform: uppercase;
        color: white;

      }
      h2 {
        margin-top: 2rem;
        font-size: 2rem;
        color: var(--third-color);
      }
    }
    &__data {

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 4rem;
      &__description {

        display: flex;
        flex-direction: column;
        gap: 0rem;
  
  
        .close{
          padding-bottom: 2rem;
        }
  
        .open{
          padding-bottom: 2rem;
        }
        .holiday{
          padding-top: 1rem;
        }
        h4 {
          font-size: 1.5rem;
          color: var(--third-color)

          
        }
        p {
          text-align: justify;
          margin-top: 20px;

        }
        div {
          p {
            strong {
              text-transform: uppercase;

            }
          }
        }
      }
      &__form {
        display: flex;
        border: 1px dotted white;
        flex-direction: column;
        align-items: center;
        padding-top: 2rem;
        gap: 2rem;
        h4 {
          font-size: 1.5rem;
          text-align: center;
          color: var(--third-color);
        }

        p{
          text-align: justify;
        }
        
      }
    }
  }
}
`;

export default Contact