import React from "react";
import styled from "styled-components";
import Title from "./Title";
import { motion } from "framer-motion";
import { useScroll } from "./useScroll";
import { contactAnimation } from "animation";
import ImageGallery from "react-image-gallery";

import party1 from "../assets/kids/party1.jpg";

function Party() {
  const [element, controls] = useScroll();
  const images = [
    {
      original: "https://globalmira.s3.ap-southeast-2.amazonaws.com/party1.jpg",
      thumbnail:
        "https://globalmira.s3.ap-southeast-2.amazonaws.com/party1.jpg",
    },
    {
      original: "https://globalmira.s3.ap-southeast-2.amazonaws.com/party2.jpg",
      thumbnail:
        "https://globalmira.s3.ap-southeast-2.amazonaws.com/party2.jpg",
    },
    // {
    //   original: 'https://globalmira.s3.ap-southeast-2.amazonaws.com/party3.jpg',
    //   thumbnail: 'https://globalmira.s3.ap-southeast-2.amazonaws.com/party3.jpg',
    // },
    {
      original: "https://globalmira.s3.ap-southeast-2.amazonaws.com/party4.jpg",
      thumbnail:
        "https://globalmira.s3.ap-southeast-2.amazonaws.com/party4.jpg",
    },
    {
      original: "https://globalmira.s3.ap-southeast-2.amazonaws.com/cs16.jpg",
      thumbnail: "https://globalmira.s3.ap-southeast-2.amazonaws.com/cs16.jpg",
    },
    {
      original: "https://globalmira.s3.ap-southeast-2.amazonaws.com/cs17.jpg",
      thumbnail: "https://globalmira.s3.ap-southeast-2.amazonaws.com/cs17.jpg",
    },
  ];
  return (
    <Section id="party" ref={element}>
      <Title value="kids party" />
      <motion.div
        className="contact"
        variants={contactAnimation}
        animate={controls}
        transition={{
          delay: 0.03,
          type: "tween",
          duration: 0.8,
        }}
      >
        <div className="contact__title">
          <h2>KIDS PARTY</h2>
          <h3> WEEKEND - SCHOOL / PUBLIC HOLIDAYS </h3>
          <br />
        </div>
        <div className="contact__data">
          <div className="contact__data__description">
            <h4>
              <strong>$150</strong> (up to 6 kids)
            </h4>
            <p> (+$18.00 each additional child) </p>
            {/* <p> (+$16.00 each additional adult) </p> */}
            <br />
            <h4>Start</h4>
            <div>
              {/* <p>
                Entry for <strong>6</strong> children (including birthday child)
              </p> */}
              {/* <p>
                Boy or Girl Party Room for up to <strong>2.5 hours</strong> (includes time playing round of minigolf)
              </p> */}
              {/* <p>
               <strong>Start</strong> free play on the <strong>5</strong> arcade games
              </p> */}
              <p>
                Your Caddyshack Party table will be available to you 15mins
                before your <strong>two hour</strong> party starts. It will be
                decorated with a table cloth and a <strong>free voucher</strong>{" "}
                for each child to use at Ferrymead Golf , plus the birthday
                child gets a <strong>free cap</strong>. You can then set up your
                plates, cups and food (a popular choice is to have pizza
                delivered to Caddyshack). Each child will be given a wrist band.

              </p>
              {/* <p>
                Round of Minigolf (<strong>18</strong> themed holes indoor)
              </p>
              <p>
                <strong>Ferrymead Golf Leisure Park Voucher</strong> for each child
              </p>
              <p>
                <strong>Free</strong> Caddyshack cap for the birthday child 
              </p> */}
              {/* <p>
              Pizza (Ham & Cheese pizza) with fries 
              </p>
              <p>
              Drink - Choice of Orange juice, Coke, Lemonade
              </p> */}
              <br />
              <h4>Party time</h4>
              <p>
              You are designated your very own <strong>Party Table</strong> exclusive for your use for <strong>2 hours</strong>! The kids can come and go as they please from your Party area. We recommend utilising the first <strong>90 mins</strong> playing <strong>mini golf</strong> and the <strong>arcade games</strong> and the last 30 mins to have some food, birthday cake, open presents and of course sing Happy Birthday!
              </p>
              <br />
              <h4>Finish</h4>
              <p>
              The parents come to collect their children and then you head home and <strong>leave us to clean up the mess!  </strong>            </p>
              {/* <h4>Self Catering only</h4> */}
              {/* <p>
                We supply paper plates and plastic cups. You must bring all food pre cooked and plated. We <strong>do not</strong> supply plates and cutlery. You must bring your own.
              </p> */}
              {/* <p>
                <strong>Please bring a rubbish bag to take away all rubbish</strong>
              </p> */}
            </div>
          </div>
          <div className="contact__data__form">
            <div>
              <ImageGallery defaultImage={party1} items={images} />
            </div>
          </div>
        </div>
      </motion.div>
    </Section>
  );
}

const Section = styled.section`
overflow: hidden;

min-height: 100vh;
// background: var(--third-color);

.contact {
  color: var(--primary-color);
  margin: 0 18rem;
  &__title {
    margin: 3rem 0rem 2rem 0;
    padding-top: 2rem;
    p {
      color: var(--primary-color);
      padding: 0.5rem;
    }
    h2 {
      font-size: 2rem;
      color: var(--secondary-color);
    }
    h3 {
      font-size: 1.5rem;
      color: var(--third-color);
    }
    h1 {
      font-size: 2rem;
      color: var(--secondary-color);
      margin: 2rem 0 0 0;
    }
  }
  &__data {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    // border: 1px solid red;
    gap: 4rem;
    &__description {
      display: flex;
      flex-direction: column;
      gap: 0rem;
      h4 {
        font-size: 1.5rem;
        color: var(--secondary-color);
      }
      p {
        text-align: justify;
        margin-top: 10px;
      }
      div {
        p {
          strong {
            color: var(--third-color);
          }
        }
      }
    }
    &__form {
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // gap: 2rem;
      .div{
        background: var(--third-color);
        height: 300px;
      }
      }
    }
  }
}
@media screen and (min-width: 280px) and (max-width: 1080px) { 
  // background: var(--third-color);

  .contact {
    width: 100vw;
    margin: 0 ;
    padding: 0 0rem;
    &__title {
      margin: 0;
      text-align: center;
      p {
        font-size: 0.8rem;
        width: 80%;

      }
      h2 {
        font-size: 2rem;
        text-align: center;
      }

      h3 {
        font-size: 1.1rem;
        text-align: center;
      }
    }
    &__data {
      grid-template-columns: 1fr;
      margin: 0 2rem;
      p {
        text-align: justify;
        width: 80vw;
      }
      &__form {
        width:80vw;
        margin-bottom: 2rem;
        // border: 1px solid green;

        }
      }
    }
  }

  
}

@media screen and (min-width: 1081px) and (max-width: 1366px) { 
  // background: var(--third-color);

  .contact {

    &__title {
      
    }
    &__data {
      grid-template-columns: 1fr 1fr;
      margin: 0 0rem;
      gap: 15rem;
      // border: 1px solid green;
      &__description {
        display: flex;
        flex-direction: column;
        gap: 0rem;
        width: 300%;
        margin-bottom: 6rem;

        }
      }
      &__form {
        background: var(--third-color);

        margin-bottom: 4rem;
        border: 1px solid green;
        .div{
          background: var(--third-color);
          height: 300px;
        }
        }
      }
    }
`;

export default Party;
