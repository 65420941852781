import { useEffect, useState, useRef, useContext } from "react";
import { Suspense } from "react";

import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import "./sass/main.scss";

// Components
import Header from "./components/Header";
import Banner from "./components/Banner";
import Loader from "./components/Loader";

import { Canvas } from "@react-three/fiber";
import Model3 from "../../assets/Scene3";
import { AdaptiveDpr, AdaptiveEvents, Environment } from "@react-three/drei";
import { OrbitControls } from "@react-three/drei";
import styled from "styled-components";

import finalimg from "../../assets/Tram/hero2.jpg";

import Golfballmodel from "components/Golfballmodel";
const Container = styled.div`
  width: 100vw;
  height: auto;
  // position: fixed;
  top: 0rem;
  right: 0;
  z-index: 0;
  background-color: transparents;
  // border: 1px solid red;
  // display: flex;
  img {

    display: block;
    top: -128px;
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: -100;
  
    @media screen and (min-width: 280px) and (max-width: 1080px) {
      top: -128px;
      max-height: 50vh;
    }
    img {
      width: 100%;
      max-width: 100%;
    }

  }
`;

const Container2 = styled.div`
width: 100vw;
height: 80vh;

// display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

position: relative;
background: transparents;
cursor: grab;
  @media screen and (min-width: 280px) and (max-width: 1080px) { 
    // top: 900vh;
    width: 100%;
    background: transparents;
    margin-top: 0rem;
    height: 30vh;
    // left:0
  }
`;

const Pageloader = () => {
  const [loading, setLoading] = useState(true);
  const sectionRef = useRef(null);
  // useEffect(() => {
  //   loading
  //     ? document.querySelector("body").classList.add("loading")
  //     : document.querySelector("body").classList.remove("loading");
  // }, [loading]);

  return (
    <Container>
      <Banner />
      {/* <Container2>
        <Golfballmodel />

      </Container2> */}

      <img src={finalimg} />
    </Container>
  );
};

export default Pageloader;
