import React from 'react';
import styled from "styled-components";
import { motion } from "framer-motion";
import { videoAnimations } from "animation";
import { useScroll } from "./useScroll";
import ParticlesComponentStar from "./subComponents/ParticlesComponentStar"


function Video() {
  const [element, controls] = useScroll();
  return (
    <Section ref={element}>
      <div className="background"></div>
      {/* <ParticlesComponentStar /> */}
      <motion.div className="video"
      variants={videoAnimations}
      animate={controls}
      transition={{
        delay: 0.03,
        type: "tween",
        duration: 0.8,
      }}
      >
        
      {/* <iframe
          width="960"
          height="515"
          src="https://globalmira.s3.ap-southeast-2.amazonaws.com/video.MOV"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe> */}
        <video src="https://globalmira.s3.ap-southeast-2.amazonaws.com/video.MOV" type="video/mp4" autoPlay muted loop width={960}/>
      </motion.div>
    </Section>
  );
}

const Section = styled.section`
background-color: #015f2d;
height: 100vh;
display: flex;
justify-content: center;
align-items: center;
position: relative;
.video {
  z-index: 10;
}
@media screen and (min-width: 280px) and (max-width: 1080px) { 
  height: 100%;
  padding: 20rem 2rem;
  .video {
    video {
      width: 100vw;
      // height: 10rem;
    }
  }
}
`;

export default Video