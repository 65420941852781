import styled from "styled-components";
import ConfigDark from "../config/particlesjs-config.json";
import ConfigLight from "../config/particlesjs-config-light.json";
import themebackground from "../config/themebackground.json"
import { useCallback } from "react";

// import Particles from "react-particles-js"
import Particles from "react-tsparticles";


const Box = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -10;
  width: 100vw;
  height: 100vh;
`;


const ParticlesComponent = (props) => {
  return (
    <Box>
      <Particles 
      options = {
        [
        {
          particles: {
            number: {
              value: 0
            },
            color: {
              value: "#fff"
            },
            shape: {
              type: "circle"
            },
            opacity: {
              value: 0.5
            },
            size: {
              value: { min: 10, max: 50 },
              animation: {
                enable: true,
                speed: 100,
                sync: false,
                startValue: "min",
                destroy: "max"
              }
            },
            move: {
              enable: true,
              speed: 10,
              direction: "none",
              outModes: "destroy"
            }
          },
          interactivity: {
            events: {
              onhover: {
                enable: true,
                mode: "trail"
              }
            },
            modes: {
              trail: {
                delay: 0.005,
                quantity: 5,
                pauseOnStop: true
              }
            }
          },
          retina_detect: true,
          background: {
            color: "#000000",
            image:
              "url('https://images.pexels.com/photos/1191710/pexels-photo-1191710.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')",
            position: "50% 50%",
            repeat: "no-repeat",
            size: "cover"
          },
          backgroundMask: {
            enable: true,
            cover: {
              color: "#000000"
            }
          }
        },
        {
          backgroundMask: {
            enable: true,
            cover: {
              value: {
                r: 0,
                g: 0,
                b: 0
              }
            }
          },
          background: {
            image: "url('https://particles.js.org/images/background.jpg')",
            size: "100% 100%",
            repeat: "no-repeat"
          },
          fullScreen: {
            enable: true,
            zIndex: -1
          },
          particles: {
            color: {
              value: ["#1E00FF", "#FF0061", "#E1FF00", "#00FF9E"],
              animation: {
                enable: true,
                speed: 30
              }
            },
            move: {
              direction: "bottom",
              enable: true,
              outModes: {
                default: "out"
              },
              size: true,
              speed: {
                min: 1,
                max: 3
              }
            },
            number: {
              value: 700,
              density: {
                enable: true,
                area: 800
              }
            },
            opacity: {
              value: 1,
              animation: {
                enable: false,
                startValue: "max",
                destroy: "min",
                speed: 0.3,
                sync: true
              }
            },
            rotate: {
              value: {
                min: 0,
                max: 360
              },
              direction: "random",
              move: true,
              animation: {
                enable: true,
                speed: 60
              }
            },
            tilt: {
              direction: "random",
              enable: true,
              move: true,
              value: {
                min: 0,
                max: 360
              },
              animation: {
                enable: true,
                speed: 60
              }
            },
            shape: {
              type: ["circle", "square", "polygon"],
              options: {
                polygon: [
                  {
                    sides: 5
                  },
                  {
                    sides: 6
                  }
                ]
              }
            },
            size: {
              value: {
                min: 3,
                max: 5
              }
            },
            roll: {
              darken: {
                enable: true,
                value: 30
              },
              enlighten: {
                enable: true,
                value: 30
              },
              enable: true,
              speed: {
                min: 15,
                max: 25
              }
            },
            wobble: {
              distance: 30,
              enable: true,
              move: true,
              speed: {
                min: -15,
                max: 15
              }
            }
          }
        },
        {
          fullScreen: {
            enable: true
          },
          detectRetina: true,
          background: {
            color: "#000"
          },
          fpsLimit: 60,
          emitters: {
            direction: "top",
            life: {
              count: 0,
              duration: 0.1,
              delay: 0.1
            },
            rate: {
              delay: 0.01,
              quantity: 1
            },
            size: {
              width: 100,
              height: 0
            },
            position: {
              y: 100,
              x: 50
            }
          },
          particles: {
            number: {
              value: 0
            },
            destroy: {
              mode: "split",
              split: {
                count: 1,
                factor: { value: 1 / 3 },
                rate: {
                  value: 100
                },
                particles: {
                  color: {
                    value: ["#5bc0eb", "#fde74c", "#9bc53d", "#e55934", "#fa7921"]
                  },
                  stroke: {
                    width: 0
                  },
                  number: {
                    value: 0
                  },
                  collisions: {
                    enable: false
                  },
                  opacity: {
                    value: 1,
                    animation: {
                      enable: true,
                      speed: 0.6,
                      minimumValue: 0.1,
                      sync: false,
                      startValue: "max",
                      destroy: "min"
                    }
                  },
                  shape: {
                    type: "circle"
                  },
                  size: {
                    value: { min: 2, max: 3 },
                    animation: {
                      enable: false
                    }
                  },
                  life: {
                    count: 1,
                    duration: {
                      value: {
                        min: 1,
                        max: 2
                      }
                    }
                  },
                  move: {
                    enable: true,
                    decay: 0.1,
                    gravity: {
                      enable: true,
                      inverse: false,
                      acceleration: 9.81
                    },
                    speed: 10,
                    direction: "none",
                    random: true,
                    straight: false,
                    outMode: "destroy"
                  }
                }
              }
            },
            life: {
              count: 1
            },
            shape: {
              type: "line"
            },
            size: {
              value: { min: 1, max: 100 },
              animation: {
                enable: true,
                sync: true,
                speed: 150,
                startValue: "random",
                destroy: "min"
              }
            },
            stroke: {
              color: {
                value: "#303030"
              },
              width: 1
            },
            rotate: {
              path: true
            },
            move: {
              enable: true,
              gravity: {
                acceleration: 15,
                enable: true,
                inverse: true,
                maxSpeed: 100
              },
              speed: { min: 10, max: 20 },
              outModes: {
                default: "destroy",
                top: "none"
              },
              trail: {
                fillColor: "#000",
                enable: true,
                length: 10
              }
            }
          }
        },
        {
          
            fullScreen: {
              enable: true,
              zIndex: 1
            },
            particles: {
              number: {
                value: 200,
                limit: 300,
                density: {
                  enable: true,
                  value_area: 800
                }
              },
              color: {
                value: "#ffffff"
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 0,
                  color: "#000000"
                },
                polygon: {
                  nb_sides: 5
                },
                image: {
                  src: "images/github.svg",
                  width: 100,
                  height: 100
                }
              },
              opacity: {
                value: 1,
                random: true,
                anim: {
                  enable: true,
                  speed: 5,
                  opacity_min: 0.5,
                  sync: false
                }
              },
              size: {
                value: 25,
                random: true,
                anim: {
                  enable: true,
                  speed: 10,
                  size_min: 10,
                  sync: false
                }
              },
              line_linked: {
                enable: true,
                distance: 100,
                color: "#ffffff",
                opacity: 1,
                width: 1
              },
              move: {
                enable: true,
                speed: 3,
                direction: "none",
                random: false,
                straight: false,
                out_mode: "out",
                bounce: false,
                attract: {
                  enable: false,
                  rotateX: 600,
                  rotateY: 1200
                }
              }
            },
            interactivity: {
              detect_on: "canvas",
              events: {
                onHover: {
                  enable: true,
                  mode: "bubble",
                  parallax: {
                    enable: false,
                    force: 60,
                    smooth: 10
                  }
                },
                onClick: {
                  enable: true,
                  mode: "push"
                },
                resize: true
              },
              modes: {
                grab: {
                  distance: 400,
                  lineLinked: {
                    opacity: 1
                  }
                },
                bubble: {
                  distance: 400,
                  size: 10,
                  duration: 2,
                  opacity: 1,
                  speed: 2
                },
                repulse: {
                  distance: 200
                },
                push: {
                  particles_nb: 4
                },
                remove: {
                  particles_nb: 2
                }
              }
            },
            backgroundMask: {
              enable: true,
              cover: {
                color: {
                  value: {
                    r: 0,
                    g: 0,
                    b: 0
                  }
                }
              }
            },
            retina_detect: true,
            fps_limit: 60,
            background: {
              color: {
                value: "#3d007a"
              },
              opacity: 1,
              // image: "url('https://particles.js.org/images/background3.jpg')"
            }
          
        },{
          
            fpsLimit: 60,
            fullScreen: { enable: true },
            particles: {
              number: {
                value: 50
              },
              shape: {
                type: "circle"
              },
              opacity: {
                value: 0.5
              },
              size: {
                value: 400,
                random: {
                  enable: true,
                  minimumValue: 200
                }
              },
              move: {
                enable: true,
                speed: 10,
                direction: "top",
                outModes: {
                  default: "out",
                  top: "destroy",
                  bottom: "none"
                }
              }
            },
            interactivity: {
              detectsOn: "canvas",
              events: {
                resize: true
              }
            },
            style: {
              filter: "blur(50px)"
            },
            detectRetina: true,
            themes: [
              {
                name: "light",
                default: {
                  value: true,
                  mode: "light"
                },
                options: {
                  background: {
                    color: "#f7f8ef"
                  },
                  particles: {
                    color: {
                      value: ["#5bc0eb", "#fde74c", "#9bc53d", "#e55934", "#fa7921"]
                    }
                  }
                }
              },
              {
                name: "dark",
                default: {
                  value: true,
                  mode: "dark"
                },
                options: {
                  background: {
                    color: "#080710"
                  },
                  particles: {
                    color: {
                      value: ["#004f74", "#5f5800", "#245100", "#7d0000", "#810c00"]
                    }
                  }
                }
              }
            ],
            emitters: {
              direction: "top",
              position: {
                x: 50,
                y: 150
              },
              rate: {
                delay: 0.2,
                quantity: 2
              },
              size: {
                width: 100,
                height: 0
              }
            }
          
        },{
          backgroundMode: {
            enable: true
          },
          fpsLimit: 60,
          particles: {
            number: {
              value: 0
            },
            collisions: {
              enable: false
            },
            color: {
              value: "#ffffff"
            },
            shape: {
              type: "circle"
            },
            opacity: {
              value: { min: 0.3, max: 0.8 }
            },
            size: {
              value: { min: 1, max: 10 }
            },
            move: {
              enable: true,
              size: true,
              speed: 5,
              direction: "none",
              outModes: {
                default: "destroy"
              },
              trail: {
                enable: true,
                fillColor: "#000000",
                length: 3
              }
            }
          },
          detectRetina: true,
          background: {
            color: "#000"
          },
          emitters: {
            direction: "none",
            rate: {
              delay: 0.25,
              quantity: 10
            },
            position: {
              x: 50,
              y: 50
            },
            size: {
              width: 0,
              height: 0
            },
            spawnColor: {
              value: "#ff0000",
              animation: {
                h: {
                  enable: true,
                  speed: 5
                },
                l: {
                  enable: true,
                  speed: 0,
                  offset: {
                    min: 20,
                    max: 80
                  }
                }
              }
            }
          }
        },
        
        {
          fpsLimit: 60,
  particles: {
    number: {
      value: 0,
      density: {
        enable: true,
        value_area: 800
      }
    },
    color: {
      value: "#ff0000",
      animation: {
        enable: true,
        speed: 180,
        sync: true
      }
    },
    shape: {
      type: "circle",
      stroke: {
        width: 0,
        color: "#000000"
      },
      polygon: {
        nb_sides: 5
      },
      image: {
        src: "https://cdn.matteobruni.it/images/particles/github.svg",
        width: 100,
        height: 100
      }
    },
    opacity: {
      value: 0.5,
      random: false,
      anim: {
        enable: false,
        speed: 3,
        opacity_min: 0.1,
        sync: false
      }
    },
    size: {
      value: 50,
      random: {
        enable: true,
        minimumValue: 10
      },
      animation: {
        enable: true,
        speed: 5,
        minimumValue: 10,
        sync: true,
        startValue: "min",
        destroy: "max"
      }
    },
    links: {
      enable: false
    },
    move: {
      enable: true,
      speed: 10,
      direction: "none",
      random: false,
      straight: false,
      outMode: "destroy",
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200
      }
    }
  },
  interactivity: {
    detectsOn: "window",
    events: {
      onhover: {
        enable: true,
        mode: "trail"
      },
      onclick: {
        enable: true,
        mode: "push"
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1
        }
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 0.8,
        speed: 3
      },
      repulse: {
        distance: 200
      },
      push: {
        particles_nb: 4
      },
      remove: {
        particles_nb: 2
      },
      trail: {
        delay: 0.005,
        quantity: 5
      }
    }
  },
  retina_detect: true,
  background: {
    color: "#000000",
    image: "url('https://images.pexels.com/photos/1191710/pexels-photo-1191710.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2')",
    position: "50% 50%",
    repeat: "no-repeat",
    size: "cover"
  },
  backgroundMask: {
    enable: true,
    cover: {
      color: "#000000"
    }
  }
        },
        // Green and Gold
        {
          
            "particles": {
              "number": {
                "value": 670,
                "density": {
                  "enable": true,
                  "value_area": 1000
                }
              },
              "color": {
                "value": "#116007"
              },
              "shape": {
                "type": "circle",
                "stroke": {
                  "width": 0.06,
                  "color": "#116007"
                },
                "polygon": {
                  "nb_sides": 5
                },
                "image": {
                  "src": "img/github.svg",
                  "width": 100,
                  "height": 100
                }
              },
              "opacity": {
                "value": 0.18,
                "random": true,
                "anim": {
                  "enable": true,
                  "speed": 0.01,
                  "opacity_min": 0.065,
                  "sync": true
                }
              },
              "size": {
                "value": 60,
                "random": false,
                "anim": {
                  "enable": true,
                  "speed": 4,
                  "size_min": 55,
                  "sync": false
                }
              },
              "line_linked": {
                "enable": false,
                "distance": 1500,
                "color": "#ffffff",
                "opacity": 0.4,
                "width": 2
              },
              "move": {
                "enable": true,
                "speed": 1,
                "direction": "none",
                "random": true,
                "straight": false,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                  "enable": false,
                  "rotateX": 1600,
                  "rotateY": 200
                }
              }
            },
            "interactivity": {
              "detect_on": "canvas",
              "events": {
                "onhover": {
                  "enable": false,
                  "mode": "bubble"
                },
                "onclick": {
                  "enable": false,
                  "mode": "bubble"
                },
                "resize": true
              },
              "modes": {
                "grab": {
                  "distance": 400,
                  "line_linked": {
                    "opacity": 0.5
                  }
                },
                "bubble": {
                  "distance": 36.54347455356053,
                  "size": 33,
                  "duration": 0.3,
                  "opacity": 3,
                  "speed": 3
                },
                "repulse": {
                  "distance": 56.84540486109416,
                  "duration": 0.4
                },
                "push": {
                  "particles_nb": 4
                },
                "remove": {
                  "particles_nb": 2
                }
              }
            },
            "retina_detect": true
        },
        //green and gold 2
          {
            "particles": {
              "number": {
                "value": 3,
                "density": {
                  "enable": true,
                  "value_area": 800
                }
              },
              "color": {
                "value": "#1da70d"
              },
              "shape": {
                "type": "circle",
                "stroke": {
                  "width": 0.1,
                  "color": "#000100"
                },
                "polygon": {
                  "nb_sides": 5
                },
                "image": {
                  "src": "img/github.svg",
                  "width": 100,
                  "height": 100
                }
              },
              "opacity": {
                "value": 0.4,
                "random": true,
                "anim": {
                  "enable": false,
                  "speed": 23,
                  "opacity_min": 0.3,
                  "sync": false
                }
              },
              "size": {
                "value": 70,
                "random": false,
                "anim": {
                  "enable": true,
                  "speed": 1,
                  "size_min": 50,
                  "sync": false
                }
              },
              "line_linked": {
                "enable": false,
                "distance": 500,
                "color": "#ffffff",
                "opacity": 0.9,
                "width": 2
              },
              "move": {
                "enable": true,
                "speed": 4,
                "direction": "none",
                "random": true,
                "straight": false,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                  "enable": false,
                  "rotateX": 600,
                  "rotateY": 1200
                }
              }
            },
            "interactivity": {
              "detect_on": "canvas",
              "events": {
                "onhover": {
                  "enable": true,
                  "mode": "bubble"
                },
                "onclick": {
                  "enable": false,
                  "mode": "bubble"
                },
                "resize": true
              },
              "modes": {
                "grab": {
                  "distance": 400,
                  "line_linked": {
                    "opacity": 0.5
                  }
                },
                "bubble": {
                  "distance": 231,
                  "size": 68,
                  "duration": 0.5,
                  "opacity": 0.6,
                  "speed": 1
                },
                "repulse": {
                  "distance": 56.84540486109416,
                  "duration": 0.4
                },
                "push": {
                  "particles_nb": 4
                },
                "remove": {
                  "particles_nb": 2
                }
              }
            },
            "retina_detect": true

        },
        //Let it snow
        {
          particles: {
            number: {
              value: 52,
              density: {
                enable: true,
                value_area: 631.3280775270874
              }
            },
            color: {
              value: "#fff"
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000"
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 0.5,
              random: true,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: false,
                speed: 40,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: false,
              distance: 500,
              color: "#ffffff",
              opacity: 0.4,
              width: 2
            },
            move: {
              enable: true,
              speed: 1.5,
              direction: "bottom",
              random: false,
              straight: false,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: "canvas",
            events: {
              onhover: {
                enable: false,
                mode: "bubble"
              },
              onclick: {
                enable: true,
                mode: "repulse"
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 400,
                line_linked: {
                  opacity: 0.5
                }
              },
              bubble: {
                distance: 400,
                size: 4,
                duration: 0.3,
                opacity: 1,
                speed: 3
              },
              repulse: {
                distance: 200,
                duration: 0.4
              },
              push: {
                particles_nb: 4
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true
        },
        //luc giac
        {
          
  backgroundMode: {
    enable: true,
    zIndex: 0
  },
  fpsLimit: 60,
  particles: {
    number: {
      value: 40,
      density: {
        enable: true,
        value_area: 500
      }
    },
    color: {
      value: "#ff9800"
    },
    shape: {
      type: "polygon",
      stroke: {
        width: 2,
        color: "#ff9800"
      },
      polygon: {
        nb_sides: 6
      },
      image: {
        src: "img/github.svg",
        width: 100,
        height: 100
      }
    },
    opacity: {
      value: 0.5,
      random: true
    },
    size: {
      value: 10,
      random: true
    },
    line_linked: {
      enable: false,
      distance: 200,
      color: "#ff5722",
      opacity: 0.3,
      width: 2
    },
    move: {
      enable: true,
      speed: 5,
      direction: "bottom",
      angle: 10,
      random: true,
      straight: true,
      out_mode: "out",
      bounce: false,
      attract: {
        enable: false,
        rotateX: 600,
        rotateY: 1200
      }
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onhover: {
        enable: true,
        mode: ["grab", "bubble"]
      },
      onclick: {
        enable: true,
        mode: "push"
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 0.7
        }
      },
      bubble: {
        distance: 600,
        size: 12,
        duration: 1,
        opacity: 0.8,
        speed: 2
      },
      repulse: {
        distance: 400,
        duration: 0.4
      },
      push: {
        particles_nb: 20
      },
      remove: {
        particles_nb: 10
      }
    }
  },
  retina_detect: true
        }
       
        
        
      ]
    }
      />

      {/* <Particles 
        styled={{position: "absolute", top: 0 }}
        options={themebackground
          
        }
      /> */}

          {/* <Particles
      options={{
        fullScreen: {
          enable: true,
          zIndex: 0
        },
        particles: {
          number: {
            value: 200,
            limit: 300,
            density: {
              enable: true,
              value_area: 800
            }
          },
          color: {
            value: "#ffffff"
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#000000"
            },
            polygon: {
              nb_sides: 5
            },
            image: {
              src: "images/github.svg",
              width: 100,
              height: 100
            }
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 5,
              opacity_min: 0.5,
              sync: false
            }
          },
          size: {
            value: 25,
            random: true,
            anim: {
              enable: true,
              speed: 10,
              size_min: 10,
              sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 100,
            color: "#ffffff",
            opacity: 1,
            width: 1
          },
          move: {
            enable: true,
            speed: 3,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200
            }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onHover: {
              enable: true,
              mode: "bubble",
              parallax: {
                enable: false,
                force: 60,
                smooth: 10
              }
            },
            onClick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 400,
              lineLinked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 10,
              duration: 2,
              opacity: 1,
              speed: 2
            },
            repulse: {
              distance: 200
            },
            push: {
              particles_nb: 4
            },
            remove: {
              particles_nb: 2
            }
          }
        },
        backgroundMask: {
          enable: true,
          cover: {
            color: {
              value: {
                r: 0,
                g: 0,
                b: 0
              }
            }
          }
        },
        retina_detect: true,
        fps_limit: 60,
        background: {
          color: {
            value: "#3d007a"
          },
          opacity: 1,
          // image: "url('https://particles.js.org/images/background3.jpg')"
        }
      }}
    /> */}

      {/* <Particles
        style={{ position: "absolute", top: 0 }}
        params={props.theme === "light" ? ConfigLight : ConfigDark}
      /> */}
        {/* <Particles
      id="tsparticles"
      options={{
        autoPlay: true,
        background: {
          color: {
            value: "#3d007a"
          },
          image: "",
          position: "",
          repeat: "",
          size: "",
          opacity: 1
        },
        backgroundMask: {
          composite: "destination-out",
          cover: {
            color: {
              value: "#fff"
            },
            opacity: 1
          },
          enable: false
        },
        backgroundMode: {
          enable: true,
          zIndex: -1
        },
        detectRetina: true,
        fpsLimit: 60,
        infection: {
          cure: true,
          delay: 0,
          enable: false,
          infections: 0,
          stages: []
        },
        interactivity: {
          detectsOn: "window",
          events: {
            onClick: {
              enable: false,
              mode: []
            },
            onDiv: {
              selectors: [],
              enable: false,
              mode: [],
              type: "circle"
            },
            onHover: {
              enable: false,
              mode: "attract",
              parallax: {
                enable: false,
                force: 2,
                smooth: 10
              }
            },
            resize: false
          },
          modes: {
            attract: {
              distance: 200,
              duration: 0.4,
              speed: 1
            },
            bounce: {
              distance: 200
            },
            bubble: {
              distance: 200,
              duration: 0.4
            },
            connect: {
              distance: 80,
              links: {
                opacity: 0.5
              },
              radius: 60
            },
            grab: {
              distance: 100,
              links: {
                blink: false,
                consent: false,
                opacity: 1
              }
            },
            light: {
              area: {
                gradient: {
                  start: {
                    value: "#ffffff"
                  },
                  stop: {
                    value: "#000000"
                  }
                },
                radius: 1000
              },
              shadow: {
                color: {
                  value: "#000000"
                },
                length: 2000
              }
            },
            push: {
              quantity: 4
            },
            remove: {
              quantity: 2
            },
            repulse: {
              distance: 200,
              duration: 0.4,
              speed: 1
            },
            slow: {
              factor: 3,
              radius: 200
            },
            trail: {
              delay: 0.005,
              quantity: 5,
              particles: {
                color: {
                  value: "#ff0000",
                  animation: {
                    enable: true,
                    speed: 400,
                    sync: true
                  }
                },
                collisions: {
                  enable: false,
                  bounce: {
                    horizontal: {
                      random: {}
                    },
                    vertical: {
                      random: {}
                    }
                  }
                },
                links: {
                  enable: false,
                  shadow: {},
                  triangles: {}
                },
                move: {
                  outMode: "destroy",
                  speed: 5,
                  angle: {},
                  attract: {
                    rotate: {}
                  },
                  gravity: {},
                  noise: {
                    delay: {
                      random: {}
                    }
                  },
                  outModes: {},
                  trail: {}
                },
                size: {
                  value: 50,
                  animation: {
                    enable: true,
                    speed: 5,
                    minimumValue: 1,
                    sync: true,
                    startValue: "min",
                    destroy: "max"
                  },
                  random: {}
                },
                bounce: {
                  horizontal: {
                    random: {}
                  },
                  vertical: {
                    random: {}
                  }
                },
                life: {
                  delay: {
                    random: {}
                  },
                  duration: {
                    random: {}
                  }
                },
                number: {
                  density: {}
                },
                opacity: {
                  animation: {},
                  random: {}
                },
                rotate: {
                  animation: {}
                },
                shadow: {
                  offset: {}
                },
                shape: {},
                stroke: {
                  color: {
                    value: "",
                    animation: {
                      enable: false,
                      speed: 0,
                      sync: false
                    }
                  }
                },
                twinkle: {
                  lines: {},
                  particles: {}
                }
              }
            }
          }
        },
        manualParticles: [],
        motion: {
          disable: false,
          reduce: {
            factor: 16,
            value: true
          }
        },
        particles: {
          bounce: {
            horizontal: {
              random: {
                enable: false,
                minimumValue: 12
              },
              value: 1
            },
            vertical: {
              random: {
                enable: false,
                minimumValue: 0.1
              },
              value: 1
            }
          },
          collisions: {
            bounce: {
              horizontal: {
                random: {
                  enable: false,
                  minimumValue: 0.1
                },
                value: 1
              },
              vertical: {
                random: {
                  enable: false,
                  minimumValue: 0.1
                },
                value: 1
              }
            },
            enable: true,
            mode: "bounce"
          },
          color: {
            value: "#015f2d",
            animation: {
              enable: false,
              speed: 200,
              sync: false
            }
          },
          life: {
            count: 37,
            delay: {
              random: {
                enable: false,
                minimumValue: 0
              },
              value: 0,
              sync: false
            },
            duration: {
              random: {
                enable: false,
                minimumValue: 0.0001
              },
              value: 0,
              sync: true
            }
          },
          links: {
            blink: true,
            color: {
              value: "random"
            },
            consent: false,
            distance: 100,
            enable: true,
            frequency: 0,
            opacity: 1,
            shadow: {
              blur: false,
              color: {
                value: "#00ff00"
              },
              enable: false
            },
            triangles: {
              enable: false,
              frequency: 0
            },
            width: 1,
            warp: false
          },
          move: {
            angle: {
              offset: 45,
              value: 90
            },
            attract: {
              enable: false,
              rotate: {
                x: 3000,
                y: 3000
              }
            },
            direction: "none",
            distance: 0,
            enable: true,
            gravity: {
              acceleration: 9.81,
              enable: false,
              maxSpeed: 50
            },
            noise: {
              delay: {
                random: {
                  enable: false,
                  minimumValue: 0
                },
                value: 0
              },
              enable: false
            },
            outModes: {
              default: "out"
            },
            random: false,
            size: false,
            speed: 2,
            straight: false,
            trail: {
              enable: false,
              length: 10,
              fillColor: {
                value: "#000000"
              }
            },
            vibrate: false,
            warp: false
          },
          number: {
            density: {
              enable: true,
              area: 800,
              factor: 1000
            },
            limit: 0,
            value: 100
          },
          opacity: {
            random: {
              enable: true,
              minimumValue: 0.3
            },
            value: 0.8,
            animation: {
              enable: true,
              minimumValue: 0.3,
              speed: 0.5,
              sync: false
            }
          },
          reduceDuplicates: false,
          rotate: {
            random: false,
            value: 0,
            animation: {
              enable: false,
              speed: 0,
              sync: false
            },
            direction: "clockwise",
            path: false
          },
          shadow: {
            blur: 51,
            color: {
              value: "#000000"
            },
            enable: false,
            offset: {
              x: 0,
              y: 0
            }
          },
          shape: {
            options: {},
            type: "circle"
          },
          size: {
            random: {
              enable: true,
              minimumValue: 1
            },
            value: 3,
            animation: {
              destroy: "none",
              enable: true,
              minimumValue: 1,
              speed: 3,
              startValue: "max",
              sync: false
            }
          },
          stroke: {
            width: 0,
            color: {
              value: "",
              animation: {
                enable: false,
                speed: 0,
                sync: false
              }
            }
          },
          twinkle: {
            lines: {
              enable: false,
              frequency: 0.05,
              opacity: 1
            },
            particles: {
              enable: false,
              frequency: 13,
              opacity: 1
            }
          }
        },
        pauseOnBlur: false,
        pauseOnOutsideViewport: true,
        themes: []
      }}
    /> */}
    </Box>
  );
};

export default ParticlesComponent;
