import styled from 'styled-components';
import { Row, Section } from './globalStyles';
import { orange } from './Colors';
import { motion } from 'framer-motion';


export const ButtonContainer = styled(Row)`
	justify-content: center;
	flex-flow: wrap;
	margin-top: 1rem;

	button {
		background-color: transparent;
		margin: 0 0.3rem;
	}
	button:nth-child(1) {
		border: 2px solid ${orange};

		&:hover {
			border: 2px solid transparent;
		}
	}

	button:nth-child(2) {
		padding: 8px 32px;

		display: flex;
		align-items: center;

		&:hover {
			background-color: transparent;
			color: ${orange};
		}
		> svg {
			margin-right: 0.4rem;
		}
	}
`;

